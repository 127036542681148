<template>
	<b-container class="pb-4 page">
		<b-row>
			<b-col>
				<h2 class="d-flex justify-content-center mt-5 py-1">수정 히스토리</h2>
			</b-col>
		</b-row>
		<div>
			<b-card no-body class="mt-4" header-tag="header">
				<b-tabs pills card>
					<b-tab
						v-for="report in reportList"
						:key="report.id"
						:title="title(report)"
						><div
							@click="handleModal(report)"
							class="d-flex justify-content-end my-1 pointer"
						>
							<div
								style="
									border: solid 0.1rem rgb(13, 40, 82);
									padding: 0.3em 0.4em 0.1em 0.4em;
									border-radius: 1rem;
									margin-bottom: 0.5em;
								"
							>
								<b-iconstack font-scale="1.2">
									<b-icon
										icon="file-text"
										style="color: rgb(13, 40, 82)"
									></b-icon>
								</b-iconstack>
								영수증
							</div>
						</div>
						<b-card-text
							><b-table :items="report.detail" :fields="tableFields"></b-table>
							<div class="memo">
								<b-icon
									icon="bookmark-check-fill"
									style="margin-right: 2%"
								></b-icon
								>{{ report.memo }}
							</div>
						</b-card-text>
					</b-tab>
				</b-tabs>
			</b-card>
		</div>
		<div v-if="!isHistory" style="margin-top: 2em">
			<div class="d-flex justify-content-center">더이상 데이터가 없습니다.</div>
		</div>
		<myModal
			@close="closeModal"
			:receipt="receipt"
			:noReceipt="noReceipt"
			:isReceipt="isReceipt"
		></myModal>
	</b-container>
</template>
<script>
import ApiService from "../common/api.service"
import API_URL from "../common/config"
import myModal from "./layouts/Modal.vue"
import { consoleLog, errorLog, modalErr } from "../common/utils"

export default {
	props: ["data"],
	components: { myModal },
	created() {
		if (!this.data) {
			this.$router.go(-1)
		} else {
			consoleLog("report history page start")
			this.reportList.push(this.data)
			this.getList()
		}
	},
	data() {
		return {
			reportList: [],
			tableFields: [
				{ key: "user_name", label: "이름" },
				{ key: "pre_balance", label: "이전정산" },
				{ key: "cost", label: "식비" },
				{ key: "delivery_cost", label: "배달비" },
				{ key: "balance", label: "정산" },
			],
			isHistory: true,
			isReceipt: true,
			receipt: "",
			noReceipt: "",
		}
	},
	methods: {
		// report title
		title(report) {
			const date = this.$dayjs(report.created_at)
			const reportType =
				report.type !== "기타" ? report.type : report.type_detail
			return (
				String(date.get("M") + 1) +
				"월 " +
				String(date.get("D")) +
				"일 " +
				reportType +
				" " +
				report.writer_name
			)
		},
		getList() {
			ApiService.get("reports/history", this.data.effect_report_id)
				.then(({ data }) => {
					consoleLog("report history response data: ", data)
					if (data.result_code == 5000) {
						this.showMsgBoxError("서버에서 오류가 발생했습니다.")
					} else {
						for (const report of data.data) {
							report.detail.map((user) => {
								user.balance = user.pre_balance + user.cost + user.delivery_cost
							})
							if (report.receipt) {
								report.receipt = report.receipt.startsWith("/media")
									? API_URL + report.receipt
									: report.receipt
							}
							this.reportList.push(report)
						}
						consoleLog("report list : ", this.reportList)
					}
				})
				.catch((response) => errorLog(response, "reports"))
		},
		handleTable() {
			for (const report of this.reportList) {
				report.detail.map((user) => {
					if (!(user.cost == 0 && user.delivery_cost == 0)) {
						user._rowVariant = "warning"
					}
				})
			}
		},
		// 모달창 핸들링
		handleModal(data) {
			if (data.receipt !== "" && data.receipt !== null) {
				this.isReceipt = true
				this.receipt = data.receipt
			} else {
				this.isReceipt = false
				this.noReceipt = data.no_receipt
			}
			this.$bvModal.show("modal")
		},
		closeModal() {
			this.$bvModal.hide("modal")
		},
		showMsgBoxError(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
					this.$router.push({ name: "reports" })
				})
				.catch((err) => {
					modalErr(err)
				})
		},
	},
}
</script>
<style>
.table-warning {
	--bs-table-bg: rgb(235, 245, 252);
	border-color: rgb(235, 245, 252);
}
</style>
